// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quantity-selector {
  display: flex;
  font-size: calc(var(--quantity-selector-height) / 2);
}
.quantity-selector > * {
  line-height: var(--quantity-selector-height);
  padding: 0;
  text-align: center;
}

.square {
  width: var(--quantity-selector-height);
  height: var(--quantity-selector-height);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rectangle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(1 * var(--quantity-selector-height));
  height: var(--quantity-selector-height);
  color: white;
  font-size: var(--quantity-selector-font-size);
}

.square_img {
  width: 28px;
  height: 28px;
}

.disable {
  opacity: 0.5;
}`, "",{"version":3,"sources":["webpack://./src/app/components/quantity-selector/quantity-selector.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,oDAAA;AACF;AAAE;EAIE,4CAAA;EACA,UAAA;EACA,kBAAA;AADJ;;AAiBA;EACE,sCAAA;EACA,uCAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAdF;;AAiBA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gDAAA;EACA,uCAAA;EACA,YAAA;EACA,6CAAA;AAdF;;AAiBA;EACE,WAAA;EACA,YAAA;AAdF;;AAgBA;EACE,YAAA;AAbF","sourcesContent":[".quantity-selector {\n  display: flex;\n  font-size: calc(var(--quantity-selector-height) / 2);\n  > * {\n    // border-top: var(--quantity-selector-border-width) var(--quantity-selector-border-color) solid;\n    // border-bottom:  var(--quantity-selector-border-width) var(--quantity-selector-border-color) solid;\n\n    line-height: var(--quantity-selector-height);\n    padding: 0;\n    text-align: center;\n\n    // &:first-child {\n    //   border-left: var(--quantity-selector-border-width) var(--quantity-selector-border-color) solid;\n    //   border-top-left-radius: 28px;\n    //   border-bottom-left-radius: 28px;\n    // }\n\n    // &:last-child {\n    //   border-right: var(--quantity-selector-border-width) var(--quantity-selector-border-color) solid;\n    //   border-top-right-radius: 28px;\n    //   border-bottom-right-radius: 28px;\n    // }\n  }\n}\n\n.square {\n  width: var(--quantity-selector-height);\n  height: var(--quantity-selector-height);\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.rectangle {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: calc(1 * var(--quantity-selector-height));\n  height: var(--quantity-selector-height);\n  color: white;\n  font-size: var(--quantity-selector-font-size);\n}\n\n.square_img {\n  width: 28px;\n  height: 28px;\n}\n.disable {\n  opacity: 0.5;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
