import { Component, EventEmitter, Input, Output } from '@angular/core';
import OptionGroup from '../../../smoothr-web-app-core/models/OptionGroup';
import ArticleOption from '../../../smoothr-web-app-core/models/ArticleOption';
import Article from '../../../smoothr-web-app-core/models/Article';
import { DisplayIdentifier } from '../../../smoothr-web-app-core/enums/DisplayIdentifier';
import { OrderType } from '../../../smoothr-web-app-core/enums/OrderType';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import TagUtils from '../../../smoothr-web-app-core/utils/tag-utils';
import { getAvailability, getBasePrice, getPrice, numberD, numberToCurrency } from '../../../smoothr-web-app-core/utils/utils';
import { ShortInfoModalComponent } from '../short-info-modal/short-info-modal.component';
import { ModalController } from '@ionic/angular';
import { Nutrition } from 'src/smoothr-web-app-core/models/Nutrition';
import { TranslateService } from '@ngx-translate/core';
import { ArticleRelativeBrand } from 'src/smoothr-web-app-core/enums/ArticleRelativeBrand';

@Component({
	selector: 'app-article-option-group-multiple',
	templateUrl: './article-option-group-multiple.component.html',
	styleUrls: ['article-option-group-multiple.component.scss'],
})
export class ArticleOptionGroupMultipleComponent {
	@Input() optionGroup: OptionGroup;
	@Input() selected: ArticleOption[];
	@Input() article: Article;
	@Output() selection = new EventEmitter<ArticleOption>();
	@Input() currency: string;
	@Input() preorderType: PreorderType;
	numberToCurrency = numberToCurrency;
	getPrice = getPrice;
	ot = OrderType;
	di = DisplayIdentifier;
	getBasePrice = getBasePrice;

	constructor(
		private modalCtrl: ModalController,
		private translate: TranslateService
	) {}

	increaseOption(article: Article) {
		const option = new ArticleOption();
		option.article = article;
		option.quantity = 1;
		option.group = this.optionGroup._id;
		this.selection.emit(option);
	}

	decreaseOption(article: Article) {
		const option = new ArticleOption();
		option.article = article;
		option.quantity = -1;
		option.group = this.optionGroup._id;
		this.selection.emit(option);
	}

	count(article: Article): number {
		return this.selected
			.filter(articleOption => articleOption.article._id === article._id)
			.reduce((prev, curr) => prev + curr.quantity, 0);
	}

	totalCount(): number {
		return this.selected.reduce((prev, curr) => prev + curr.quantity, 0);
	}

	hasEmptyTag(optionArticle: Article): boolean {
		return TagUtils.hasEmptyTag(optionArticle);
	}

	isHidden(article: Article): boolean {
		return this.preorderType && !getAvailability(article, OrderType.PREORDER, this.preorderType);
	}
	async openInfoClick(article: Article) {
		await ShortInfoModalComponent.show(this.modalCtrl, article);
	}
	isAllergens(article: Article) {
		if (article?.compound?.allergens) return Object.values(article?.compound?.allergens)?.find(it => it === true);
		else return null;
	}

	isAddit(article: Article) {
		if (article?.compound?.additives) return Object.values(article?.compound?.additives)?.find(it => it === true);
		else return null;
	}
	isHaveAnyValueMoreZero(article: Article) {
		return Object.values((article as Article & { nutritions: Nutrition })?.nutritions)
			.map(it => (numberD(it) ? numberD(it) : 0))
			.find(it => it > 0);
	}
	findFeature(article: Article) {
		if (article?.compound?.features) {
			delete article.compound?.features?._id;
			delete article.compound?.features?.updatedAt;
			delete article.compound?.features?.createdAt;
			delete article.compound?.features?.createdAt;
			return Object.entries(article.compound?.features)
				.filter(it => it[0] == 'new' || it[0] == 'special' || it[0] == 'topSelling')
				.filter(it => !!it[1])
				.map(it => it[0])[0];
		}
		return '';
	}
	checkPfandTag(article: Article) {
		if (article.tags?.length === 0) {
			return '';
		}
		const deposit25 = article.tags.find(it => it.identifier === 'deposit25') ? 'deposit25' : '';
		const deposit15 = article.tags.find(it => it.identifier === 'deposit15') ? 'deposit15' : '';

		return deposit15 || deposit25;
	}
	getPriceValue(article: Article) {
		const price = this.getPrice(article, OrderType.PREORDER, this.preorderType);
		if (!price) {
			return '';
		} else {
			const priceWithEuro = numberToCurrency(price, this.currency);
			const formatText = priceWithEuro.toString().split(/[,.]/);

			return (
				'<div class="price_transform"><div class="full_price">+' +
				formatText[0] +
				',' +
				'</div><div class="rest_price">' +
				formatText[1] +
				'</div><div class="rest_price_hide">' +
				formatText[1] +
				'</div></div>'
			);
		}
	}
	checkMeasurmentPlusPfand(article: Article) {
		let result = '';
		if (!this.preorderType || !article) {
			return;
		}
		const pfandValue = this.checkPfandTag(article);
		if (pfandValue) {
			result =
				this.translate.instant('pfand_text.zzgl') +
				(pfandValue === 'deposit15' ? numberToCurrency(0.15, this.currency) : numberToCurrency(0.25, this.currency)) +
				this.translate.instant('pfand_text.pfand') +
				'<br/>';
		} else {
			if (result.length > 0) {
				result = result + ')';
			}
		}
		if (article?.measurement && article?.measurement?.unit && article?.measurement?.amount && article?.basePrice?.$numberDecimal) {
			result =
				result +
				' ' +
				article.measurement?.refAmount +
				' ' +
				article.measurement?.refUnit +
				' = ' +
				this.numberToCurrency(getBasePrice(article, OrderType.PREORDER, this.preorderType), this.currency);
		}
		return result;
	}
	changeUrl(item: string) {
		let end = '.png';
		if (item == 'vegan' || item === 'vegetarian') {
			end = '.svg';
		}
		return item + '_' + (this.translate.currentLang ?? 'de') + end;
	}
	findBrandImage(article: Article) {
		if (article?.articleRelativeBrand) {
			return article?.articleRelativeBrand;
		} else {
			return '';
		}
	}
}
