import { Component, Input } from '@angular/core';
import Article from '../../../smoothr-web-app-core/models/Article';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import { OrderType } from '../../../smoothr-web-app-core/enums/OrderType';
import ArticleGroup from '../../../smoothr-web-app-core/models/ArticleGroup';
import { OrderUtils } from '../../../smoothr-web-app-core/utils/order-utils';
import { environment } from 'src/environments/environment';
import { defaultsToArticleOption, getBasePrice, getPrice, numberD, numberToCurrency } from '../../../smoothr-web-app-core/utils/utils';
import { TranslateService } from '@ngx-translate/core';
import { ArticleRelativeBrand } from 'src/smoothr-web-app-core/enums/ArticleRelativeBrand';

@Component({
	selector: 'app-item-view',
	templateUrl: './item-view.component.html',
	styleUrls: ['item-view.component.scss'],
})
export class ItemViewComponent {
	@Input() currency: string;
	price: number = 0;
	ot = OrderType;
	environment = environment;
	@Input() hasOrder = false;
	@Input() isLast = false;
	@Input() indexNumber = 0;
	priceText = '';
	numberToCurrency = numberToCurrency;
	getBasePrice = getBasePrice;
	findFeatureArray: string[] = [];
	checkVeganFeatureValue: string = '';
	constructor(private translate: TranslateService) {}

	private _article: Article;

	get article(): Article {
		return this._article;
	}

	@Input()
	set article(value: Article) {
		this._article = value;
		this.reloadPrice();
	}

	private _preorderType: PreorderType = null;

	get preorderType(): PreorderType {
		return this._preorderType;
	}

	@Input()
	set preorderType(value: PreorderType) {
		this._preorderType = value;
		this.reloadPrice();
	}

	private reloadPrice() {
		if (this.article && this.preorderType) {
			this.price = 0;
			const articleGroup = new ArticleGroup();
			articleGroup.article = this.article;
			articleGroup.groups = defaultsToArticleOption(this.article, [], this.article.defaults, OrderType.PREORDER, this.preorderType);
			articleGroup.quantity = 1;
			if (this.article && this.preorderType) {
				const articleGroup = new ArticleGroup();
				articleGroup.article = this.article;
				articleGroup.groups = defaultsToArticleOption(
					this.article,
					[],
					this.article.defaults,
					OrderType.PREORDER,
					this.preorderType
				);
				articleGroup.quantity = 1;
				const groups = this.test(articleGroup);
				if (
					groups.length > 0 &&
					this.article?.tags?.length > 0 &&
					this.article.tags?.filter(it => it.identifier === 'fromPrice').length > 0
				) {
					const DIMA = groups
						.filter(it => it.requiredAmount > 0)
						.map(it => {
							return Math.min(...it.articles.map(article => numberD(article.price) * it.requiredAmount));
						});
					this.price = DIMA.reduce((a, b) => a + b, 0) + this.price;
				} else {
					this.price = OrderUtils.articleGroupsTotalPrice([articleGroup], OrderType.PREORDER, this.preorderType);
				}
			} else {
				this.price = 0;
			}
		} else {
			this.price = 0;
		}
		if (this.price === 0) {
			this.priceText = '';
			return;
		}
		const priceText = numberToCurrency(this.price, this.currency);
		const formatText = priceText.toString().split(/[,.]/);

		this.priceText =
			'<div class="price_transform">' +
			(this.checkFromPriceTag(this.article)
				? '<div class="from_price">' + this.translate.instant('item_view.price_from') + '</div>'
				: '') +
			'<div class="full_price">' +
			formatText[0] +
			',' +
			'</div><div class="rest_price">' +
			formatText[1] +
			'</div><div class="rest_price_hide">' +
			formatText[1] +
			'</div></div>';
		this.findFeature(this.article);
		this.checkVeganFeature(this.article);
	}
	checkVeganFeature(article: Article) {
		this.checkVeganFeatureValue = Object.entries(article.compound?.features)
			.filter(it => it[0] == 'vegan' || it[0] == 'vegetarian')
			.filter(it => !!it[1])
			.map(it => it[0])[0];
	}
	findFeature(article: Article) {
		if (article?.compound?.features) {
			delete article.compound?.features?._id;
			delete article.compound?.features?.updatedAt;
			delete article.compound?.features?.createdAt;
			delete article.compound?.features?.createdAt;

			this.findFeatureArray = Object.entries(article.compound?.features)
				.filter(it => it[0] == 'new' || it[0] == 'special' || it[0] == 'topSelling' || it[0] == 'vegan' || it[0] == 'vegetarian')
				.filter(it => !!it[1])
				.map(it => it[0]);
			return;
		}
		this.findFeatureArray = [];
	}
	checkPfandTag(article: Article) {
		if (article.tags?.length === 0) {
			return '';
		}
		const deposit25 = article.tags.find(it => it.identifier === 'deposit25') ? 'deposit25' : '';
		const deposit15 = article.tags.find(it => it.identifier === 'deposit15') ? 'deposit15' : '';

		return deposit15 || deposit25;
	}
	test(articleGroup: ArticleGroup) {
		let pages = [];
		for (const group of articleGroup.article.groups) {
			pages.push(group);
		}
		if (pages.length === 0) {
			pages.push([]);
		}
		if (pages.length > 1) {
			pages = pages.filter(page => page !== null);
		}
		const indicesOfRecommendations = [];
		pages.forEach((page, pageIndex) => {
			if (page.length > 0 && articleGroup.article.recommendations.find(recommendation => recommendation.group === page[0]._id)) {
				indicesOfRecommendations.push(pageIndex);
			}
		});
		return pages;
	}
	checkFromPriceTag(article: Article) {
		return this.article?.tags?.length > 0 && this.article.tags?.filter(it => it.identifier === 'fromPrice').length > 0;
	}
	checkMeasurmentPlusPfand(article: Article) {
		let result = '';

		const pfandValue = this.checkPfandTag(article);
		if (pfandValue) {
			result =
				this.translate.instant('pfand_text.zzgl') +
				(pfandValue === 'deposit15' ? numberToCurrency(0.15, this.currency) : numberToCurrency(0.25, this.currency)) +
				this.translate.instant('pfand_text.pfand') +
				'<br/>';
		} else {
			if (result.length > 0) {
				result = result + ')';
			}
		}
		if (article?.measurement && article?.measurement?.unit && article?.measurement?.amount && article?.basePrice?.$numberDecimal) {
			result =
				result +
				' ' +
				article.measurement?.refAmount +
				' ' +
				article.measurement?.refUnit +
				' = ' +
				this.numberToCurrency(getBasePrice(article, OrderType.PREORDER, this.preorderType), this.currency);
		}
		return result;
	}
	findBrandImage(article: Article) {
		if (article?.articleRelativeBrand) {
			return article?.articleRelativeBrand;
		} else {
			return '';
		}
	}
}
