import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
	selector: 'app-quantity-selector',
	templateUrl: './quantity-selector.component.html',
	styleUrls: ['quantity-selector.component.scss'],
})
export class QuantitySelectorComponent implements OnInit {
	@Output()
	increase = new EventEmitter<void>();
	@Output()
	decrease = new EventEmitter<void>();
	@Input()
	min = 0;
	@Input() amountOfItemsInBoxAvailable = 0;
	@Input() checkIfBox: boolean = false;
	@Input() isSanifair: boolean = false;

	constructor() {}

	private _quantity: number;

	get quantity(): number {
		return this._quantity;
	}

	@Input()
	set quantity(value: number) {
		this._quantity = value;
	}

	ngOnInit() {}

	increaseQuantity() {
		if (this.checkIfBox) {
			if (this.amountOfItemsInBoxAvailable > 0) {
				this.increase.emit();
			}
		} else {
			this.increase.emit();
		}
	}

	decreaseQuantity() {
		if (this._quantity <= this.min) {
			return;
		}
		this.decrease.emit();
	}
}
